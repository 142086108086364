<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between align-center">
        <div class="d-flex align-start" style="gap:0.8em">
          <v-icon color="primary" class="pt-1">mdi-comment-text-outline</v-icon>
          <div class="d-flex flex-column justify-center">
            <span
              class="font-weight-bold primary--text"
              :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
            >
              Agregar Comentario
            </span>
            <span class="caption blue-grey--text">Dia: {{data.fecha | largeDate}}</span>
          </div>
        </div>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <validation-observer ref="COMMENT_FORM" tag="div">
          <v-row v-if="commentInfo.text !== null">
            <v-col cols="12" class="pt-2 pb-0 mt-2">
              <label-form text="Comentario:" />
              <span
                class="black--text font-weight-medium"
               v-text="commentInfo.text" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-2 pb-0 mt-4">
              <label-form text="Escribir comentario" required />
              <validation-provider name="comentario" vid="commentInfo.comentario" rules="required" v-slot="{ errors }">
                <v-textarea
                  :value="data.comentario"
                  v-model="commentInfo.comentario"
                  outlined
                  dense
                  :error-messages="errors[0]"
                  rows="2"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="save"
        >
          <v-icon dark left>mdi-pencil</v-icon>
          {{data.text !== null ? 'Actualizar' : 'Agregar'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {saveComment} from '@/services/vendedores'
const dataDefault = () => ({
  comentario: '',
});

export default {
  name:'ModalComentario',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      commentInfo: dataDefault(),
      action: 'crear',
    }
  },
  watch: {
    showModal (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.showModal = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.commentInfo = {...val, ...dataDefault() }
      }
      else
        this.commentInfo = dataDefault();
    }
  },
  methods: {
    cerrar() {
      this.showModal = false;
      this.commentInfo = dataDefault();
      this.$refs.COMMENT_FORM.reset();
    },
    async save() {
      const valid = await this.$refs.COMMENT_FORM.validate();
      if(valid) {
        try {
          this.loading = true;
          console.log(this.commentInfo)
          const  data  = await saveComment(this.commentInfo);
          this.$emit('update', true)
          this.cerrar();
          this.$root.$showAlert(data.message, 'success');
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loading = false;
        }
      }
    },
  },
}
</script>
